import {useState} from 'react'

const Player = ({ name, thumbnail, id, handleDelete }) => {
    const [points, setPoints] = useState(0)
    const [updatedPoints, setUpdatedPoints] = useState(0)

    const handleFocus = (e) => {
        e.target.select()
    }

    return (
        <div className="player">
            <img src={'images/' + thumbnail} className='player-icon'/>
            <h4 className='player-name' contentEditable="true">{name}</h4>
            <h3 className='player-points' style={{color: points < 0 ? "#9b1a0a" : "black"}}>{points}</h3>
        <form className='points-form'>
            {/* <label>Update Points</label> */}
            <input
                type="number"
                id="points-val"
                value={updatedPoints}
                onFocus={handleFocus}
                onChange={(e) => {
                    setUpdatedPoints(e.target.value)
                }} />
            <button className='button-3'
                onClick={(e) => {
                e.preventDefault()
                if (isNaN(parseInt(updatedPoints, 10))) {
                    alert("That's not a number!")
                    return
                }
                setPoints(parseInt(points, 10) + parseInt(updatedPoints, 10))
                setUpdatedPoints(0)
            }}>Update</button>
            <button
                className='delete-player button-4'
                onClick={(e) => {
                    e.preventDefault()
                    handleDelete(id)
            }}>Delete</button>
        </form>
    </div>
    );
}
 
export default Player;