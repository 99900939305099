import Player from "./player";

import { useState } from "react";

const PlayerList = ({ allPlayers, setAllPlayers }) => {
    const handleDelete = (id) => {
        if (window.confirm('Delete Player?')) {
            // This is a bug. The handleDelete() function is clearing the state for some Players.
            const updatedPlayers = allPlayers.filter((player) => player.id !== id)
            setAllPlayers(updatedPlayers)
        }
    }

    return (
        <div className="playerList">
            {allPlayers.map((player, index) => (
               <div className="all-players">
                    <Player name={player.name} thumbnail={player.thumbnail} id={player.id} handleDelete={handleDelete} key={player.id}/>
                    {index !== 3 && <hr className="horizontal-rule"/>}
               </div>
            ))}
        </div>
    );
}
 
export default PlayerList;