import Navbar from './navbar';
import PlayerList from './playerList';
import { useState } from 'react';


const Home = () => {
    const yellowPlayer = {name: 'Yellow Skull', thumbnail: 'YellowSkullMedium.png', id: 0}
    const greenPlayer = {name: 'Green Feather', thumbnail: 'GreenFeatherMedium.png', id: 1}
    const bluePlayer = {name: 'Blue Flower', thumbnail: 'BlueFlowerMedium.png', id: 2}
    const orangePlayer = {name: 'Orange Worm', thumbnail: 'OrangeWormMedium.png', id: 3}
    const [allPlayers, setAllPlayers] = useState([yellowPlayer, greenPlayer, bluePlayer, orangePlayer])

    return (
        <div className="home">
            <Navbar />
            <div className="tan">
                <PlayerList allPlayers={allPlayers} setAllPlayers={setAllPlayers}/>
            </div>
        </div>
    );
}
 
export default Home;